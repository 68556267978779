// import { makeStyles } from '@fluentui/react-components'
import { makeStyles, shorthands } from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import promptSuggestions from '../../../prompt-library.json';
import ProGPTGraphIcon from '../../../assets/progpt-grph-0524-icon-rgb.png';
import { customColors, customFonts } from '../../../styles';
import { ChatWindowBlock } from './ChatWindowBlock';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { IGPT } from '../../../libs/models/GPT';
import ChatWindowBlockWrapper from './ChatWindowBlockWrapper';

const useClasses = makeStyles({
    container: {
        width: '70%',
        height: '70%',
        display: 'flex',
        flexDirection: 'column',
    },
    greeting: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    greetingIcon: {
        ...shorthands.padding('10px', 0, 0, '15px'),
        width: '10%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        height: '100%',
        width: '100%',
        maxWidth: '75px',
    },
    greetingMessage: {
        width: '100%',
        fontSize: '50px',
        fontWeight: 'bold',
        fontFamily: customFonts.TitleFont,
        color: customColors.protivitiDarkBlue,
        ...shorthands.padding(0, 0, 0, '14px'),
        wordWrap: 'normal',
        lineHeight: '1',
        // wordBreak: 'break-word', // Add this line
    },
    subtitle: {
        fontSize: '2em',
        marginBottom: '30px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '300',
        color: customColors.headerLightGray,
        fontFamily: customFonts.TitleFont,
    },
});

interface ChatWindowScreenProps {
    setValue: React.Dispatch<React.SetStateAction<string>>;
    isDocumentChat: boolean
}

export const ChatWindowScreen = ({ setValue, isDocumentChat }: ChatWindowScreenProps ) => {
    const classes = useClasses();
    const _chatType = isDocumentChat ? "Document" : "Regular";
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const [selectedGPT, setSelectedGPT] = useState<IGPT | undefined>(undefined);
    const { userGPTs } = useAppSelector((state: RootState) => state.gpts);

    const [blobUrl, setBlobUrl] = useState<string>('');
    useEffect(() => {

        const fetchBlobUrl = async () => {
            try {
                
                if(selectedGPT?.gptIconFileName){
                    const url = process.env.REACT_APP_CDN_IMAGE_URL + selectedGPT?.gptIconFileName;
                    setBlobUrl(url);  // Set the URL in state
                }
                
            } catch (error) {
                console.error('Error fetching SAS URL:', error);
            }
        };
    
        fetchBlobUrl();
    }, [selectedGPT?.gptIconFileName]);

    useEffect(() => {
            const foundGPT = userGPTs.find((gpt) => gpt.gptEndpoint === conversations[selectedId].gptEndpoint);
            setSelectedGPT(foundGPT);
    }, [selectedId]);

    return (
        <div className={classes.container}>
            <div className={classes.greeting}>
                <div className={classes.greetingIcon}>
                    <img className={classes.icon} src={selectedGPT?.id === process.env.REACT_APP_DEFAULT_PROGPT ? ProGPTGraphIcon : (selectedGPT?.gptIconFileName ? blobUrl: selectedGPT?.cardImageURL)} />
                </div>
                <p className={classes.greetingMessage}> Hi, I&apos;m {selectedGPT ? selectedGPT.name : 'ProGPT'}! </p>
            </div>
            <div style={{ paddingRight: '105px', lineHeight: 'normal' }}>
                <p className={classes.subtitle}>{selectedGPT?.description}</p>
            </div>
            {selectedGPT?.id === process.env.REACT_APP_DEFAULT_PROGPT ? (
                Object.keys(promptSuggestions).map((chatType, index) => {
                    if (chatType === _chatType) {
                        const categorySuggestions = promptSuggestions[chatType as keyof typeof promptSuggestions];
                        return (
                            <React.Fragment key={index}>
                                {Object.keys(categorySuggestions).map((childCategory, childIndex) => {
                                    const suggestions =
                                        categorySuggestions[childCategory as keyof typeof categorySuggestions];
                                    return (
                                        <ChatWindowBlock
                                            key={childIndex}
                                            category={childCategory}
                                            suggestions={suggestions}
                                            setValue={setValue}
                                        />
                                    );
                                })}
                            </React.Fragment>
                        );
                    } else {
                        return null;
                    }
                })
            ) : (
                <ChatWindowBlockWrapper promptLibrary={selectedGPT?.promptLibrary} setValue={setValue} />
            )}
        </div>
    );
};
