import {
    Avatar,
    Button,
    Card,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Text,
    makeStyles,
    shorthands,
} from '@fluentui/react-components';
import React, { useCallback, useEffect, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useChat } from '../../libs/hooks';
import { IGPT } from '../../libs/models/GPT';
import { customColors, customFonts } from '../../styles';
import { MoreVerticalFilled } from '@fluentui/react-icons';

const useStyles = makeStyles({
    card: {
        width: '400px',
        height: '170px',
        ...shorthands.margin('10px'),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F7F7F8',
        ...shorthands.borderLeft('5px', 'solid', '#D9D9D9'),
        ...shorthands.transition('backgroundColor', '0.3s', 'ease-in-out'),
        '&:hover': {
            backgroundColor: '#FFFFFF',
            ...shorthands.borderLeft('5px', 'solid', customColors.protivitiOrange),
            ...shorthands.borderRight('1px', 'solid', customColors.protivitiOrange),
            ...shorthands.borderBottom('1px', 'solid', customColors.protivitiOrange),
            ...shorthands.borderTop('1px', 'solid', customColors.protivitiOrange),
            boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.2)',
            cursor: 'pointer',
        },
        '&:disabled': {
            opacity: 0.5,
            pointerEvents: 'none',
            cursor: 'progress',
        },
        '@media screen and (max-width: 1200px)': {
            width: `${parseInt('300px', 10) * 0.9}px`,
            height: `${parseInt('150px', 10) * 0.9}px`,
        },
    },
    image: {
        width: '72px',
        height: '72px',
        objectFit: 'cover',
        marginBottom: '10px',
        marginRight: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
    description: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: '10px',
        '&:hover': {
            backgroundColor: 'transparent',
            //color: 'white',
            cursor: 'pointer',
        },
    },
    headerText: {
        textAlign: 'left',
        fontWeight: '600',
        fontSize: '20px',
        fontFamily: customFonts.TitleFont,
        lineHeight: '19.2px',
        marginBottom: '5px',
    },

    descriptionText: {
        fontWeight: '400',
        fontSize: '14px',
        textAlign: 'left',
        color: '#696969',
    },
    feedbackButton: {
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        ...shorthands.border('none'),
        backgroundColor: 'transparent',
        color: '#1976D2',
        fontWeight: '500',
        textAlign: 'center',
        ...shorthands.padding('5px', '10px'),
        fontSize: '14px',
        '&:hover': {
            textDecoration: 'underline',
            WebkitTextDecorationColor: '#1976D2',
        },
    },
    toastMessage: {
        fontSize: '18px',
        color: 'black',
        fontFamily: customFonts.TitleFont,
        backgroundColor: '#F7F7F8',
        border: '1px solid black',
        marginTop: '33px',
    },

    moreButton: {
        position: 'absolute',
        top: '10px',
        right: '5px',
        ...shorthands.border('none'),
        backgroundColor: 'transparent',
        ...shorthands.padding('5px', '10px'),
        '&:hover': {
            textDecoration: 'underline',
            WebkitTextDecorationColor: '#1976D2',
        },
    },
});

interface ExploreGPTTileProps {
    gpt: IGPT;
    isFunctionTriggered: boolean;
    handleButtonClick: (event: React.MouseEvent, gptId: string) => void;
    handleLearnMore: (gptId: string) => void;
}

export const ExploreGPTTile: React.FC<ExploreGPTTileProps> = ({ gpt, handleButtonClick, handleLearnMore }) => {
    const classes = useStyles();
    const chat = useChat();
    const [totalRating, setTotalRating] = useState(gpt.totalRating);
    const navigate = useNavigate();
    const isRatingEnabled = process.env.REACT_APP_ENABLE_RATING === 'true';
    const [isCreatingChat, setIsCreatingChat] = useState(false);

    const [blobUrl, setBlobUrl] = useState<string>('');
    useEffect(() => {
        const fetchBlobUrl = async () => {
            try {
                if(gpt?.gptIconFileName){
                    const url = process.env.REACT_APP_CDN_IMAGE_URL + gpt?.gptIconFileName;
                    setBlobUrl(url);
                }

                
            } catch (error) {
                console.error('Error fetching SAS URL:', error);
            }
        };
    
        fetchBlobUrl();
    }, [gpt.gptIconFileName]);


    const createChat = useCallback(async () => {
        setIsCreatingChat(true);
        var newChatId = await toast.promise(chat.createChat(gpt), {
            pending: {
                render: 'Creating chat...',
                position: 'top-right',
                className: classes.toastMessage,
            },
        });
        if (newChatId == 'Chat session cannot be created more than 20.')
            toast.error('More than 20 chat sessions cannot be created. Remove a chat to create a new chat!', {
                className: classes.toastMessage,
            });
        else if (newChatId.startsWith('Unable to create new chat'))
            toast.error(null, {
                className: classes.toastMessage,
            });
        else navigate(`/chat/${newChatId}`);
        setIsCreatingChat(false);
    }, []);

    useEffect(() => {
        setTotalRating(gpt.totalRating);
    }, [gpt]);

    return (
        <Card
            className={`${classes.card}`}
            onClick={createChat}
            style={{
                cursor: isCreatingChat ? 'progress' : 'pointer',
                opacity: isCreatingChat ? 0.5 : 1,
                pointerEvents: isCreatingChat ? 'none' : 'auto',
            }}
        >
            <div className={classes.description}>
                <Avatar
                    className={classes.image}
                    aria-label="GPT Icon"
                    image={{
                        src: gpt.gptIconFileName ? blobUrl: gpt.cardImageURL,
                    }}
                />

                {/**<Image src={gpt.cardImageURL} className={classes.image} alt={`${gpt.name} icon`} />**/}
                <div
                    style={{
                        marginLeft: '10px !important',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'end',
                        alignContent: 'start',
                    }}
                >
                    <Text className={classes.headerText}>{gpt.name}</Text>
                    <Text className={classes.descriptionText}>{gpt.cardShortDescription}</Text>
                </div>
                {isRatingEnabled && (
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '10px',
                            left: '20px',
                            padding: '5px',
                            backgroundColor: 'transparent',
                            fontWeight: '600',
                            fontSize: '18px',
                            fontFamily: customFonts.TitleFont,
                            //borderRadius: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            //display: 'block',
                        }}
                    >
                        <FaStar style={{ marginRight: '5px', color: '#FFC309' }} /> {}
                        {totalRating?.toFixed(1)} {}
                    </div>
                )}
                <Menu>
                    <MenuTrigger>
                        <Button
                            appearance="transparent"
                            icon={<MoreVerticalFilled />}
                            aria-label="More options"
                            className={classes.moreButton}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleLearnMore(gpt.id);
                                }}
                            >
                                Learn More
                            </MenuItem>
                            <MenuItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleButtonClick(e, gpt.id);
                                }}
                            >
                                Submit Feedback
                            </MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
                {/* <ContextualMenu {...menuProps} /> */}
                {/* <button className={classes.feedbackButton} onClick={(event) => handleButtonClick(event, gpt.id)}>
                    Submit Feedback
                </button> */}
            </div>
        </Card>
    );
};
