import {
    Button,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    PositioningImperativeRef,
    Spinner,
    Textarea,
    makeStyles,
    mergeClasses,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';
import { Attach20Filled, Dismiss12Filled, StopFilled } from '@fluentui/react-icons';
import debug from 'debug';
import React, { useEffect, useMemo, useRef } from 'react';
import { Constants } from '../../Constants';
import { ReactComponent as Arrow } from '../../assets/arrow-smallupward-icon-black-rgb.svg';
import { GetResponseOptions, useChat } from '../../libs/hooks/useChat';
import { ChatMessageType } from '../../libs/models/ChatMessage';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { removeAlert } from '../../redux/features/app/appSlice';
import { setDocumentsToConversation } from '../../redux/features/conversations/conversationsSlice';
import { Breakpoints, SharedStyles, customColors, customFonts } from '../../styles';
import { Alerts } from '../shared/Alerts';
import { ChatStatus } from './ChatStatus';
import { PopoverContent } from './prompt-suggestion/PopoverContent';
import { IGPT } from '../../libs/models/GPT';
import { useGPT } from '../../libs/hooks/useGPT';
import { setUserGPTs } from '../../redux/features/gpt/gptSlice';
import { ChatMemorySource } from '../../libs/models/ChatMemorySource';
import { updateBotResponseStatus, updateChatInput } from '../../redux/features/currentmessages/currentmessagesSlice';
import { shallowEqual } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

const log = debug(Constants.debug.root).extend('chat-input');

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '80%', // width 80% is also alright
        width: '85%',
        maxWidth: '85%',
        ...shorthands.margin('auto'),
        ...Breakpoints.small({
            ...shorthands.margin('0', 'auto'),
            ...shorthands.padding('5px'),
        }),
    },
    stopRespondingButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    stopRespondingButton: {
        color: customColors.darkNavy,
        ...shorthands.borderRadius('0'),
        ...shorthands.border('1px', 'solid', customColors.protivitiOrange),
        ...shorthands.margin('5px'),
        '&:hover': {
            backgroundColor: customColors.darkNavy,
            color: 'white',
            ...shorthands.border('none'),
            ...shorthands.border('1px', 'solid', customColors.protivitiOrange),
        },
    },
    typingIndicator: {
        maxHeight: '28px',
    },
    content: {
        display: 'flex',
        minHeight: '30px',
        flexDirection: 'row',
        textAlign: 'center',
        maxHeight: '250px',
        ...shorthands.border('0.5px', 'solid', 'rgb(0, 64, 104)'),
        ...shorthands.borderBottom('1px', 'solid', 'rgb(0, 64, 104)'),
        '& > span:hover, & > span:active, & > span:after, & > span:focus, & > span:focus-within': {
            ...shorthands.border('0px'),
        },
    },
    content1: {
        minHeight: '30px',
        width: '100%',
    },
    input: {
        width: '100%',
        fontSize: '2em',
        ...shorthands.border('0px'),
    },
    textarea: {
        maxHeight: '250px',
        ...shorthands.padding('5px'),
        minHeight: '10px',
        minWidth: '200px',
        lineHeight: '1em',
        fontFamily: customFonts.Lato,
        ...shorthands.border('0px'),
        alignContent: 'center',
        '&::placeholder': {
            fontSize: '1em',
        },

        '&::-moz-placeholder': {
            /* Firefox 19+ */ fontSize: '1em',
        },

        '&:-ms-input-placeholder': {
            /* IE 10+ */ fontSize: '1em',
        },

        '&::-webkit-input-placeholder': {
            /* Chrome/Opera/Safari */ fontSize: '1em',
        },
        '::-webkit-resizer': {
            display: 'none',
        },
        '::-webkit-scrollbar': {
            width: '0rem !important',
        },
    },
    span: {
        color: tokens.colorNeutralForeground2,
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
    },
    spanContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '5px',
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '0.5vh',
        marginBottom: '0.5vh',
    },
    essentials: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto', // align to right
    },
    functional: {
        display: 'flex',
        flexDirection: 'row',
    },
    dragAndDrop: {
        ...shorthands.border(tokens.strokeWidthThick, ' solid', tokens.colorBrandStroke1),
        ...shorthands.padding('8px'),
        textAlign: 'center',
        backgroundColor: tokens.colorNeutralBackgroundInvertedDisabled,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorBrandForeground1,
        caretColor: 'transparent',
    },
    sendButton: {
        justifySelf: 'center',
        ...shorthands.borderRadius('0'),
        ...shorthands.border('1px', 'solid', customColors.protivitiDarkBlue),
        ...shorthands.margin('5px'),
        maxHeight: '120px',
        alignSelf: 'center',
        ...shorthands.transition('all', '0.3s', 'ease'),

        // styles for the arrow icon
        '& > span > svg': {
            fill: customColors.protivitiDarkBlue,
            stroke: customColors.protivitiDarkBlue,
            ...shorthands.transition('all', '0.3s', 'ease'),
        },

        ':hover': {
            backgroundColor: customColors.protivitiDarkBlue,
            ...shorthands.border('1px', 'solid', customColors.protivitiOrange),
            '& > span > svg': {
                fill: 'white',
                stroke: 'white',
            },

            ':active': {
                backgroundColor: customColors.protivitiOrange,
                color: 'white',
                ...shorthands.transition('all', '0s'),
            },
        },

        ':disabled': {
            color: 'gray',
            ...shorthands.border('1px', 'solid', 'lightgray'),
            cursor: 'default',
            '& > span > svg': {
                fill: 'lightgray',
                stroke: 'lightgray',
            },

            ':hover': {
                color: 'gray',
                backgroundColor: 'white',
                ...shorthands.border('1px', 'solid', 'lightgray'),
                cursor: 'default',
                '& > span > svg': {
                    fill: 'lightgray',
                    stroke: 'lightgray',
                },

                ':active': {
                    color: 'gray',
                    backgroundColor: 'white',
                    ...shorthands.border('1px', 'solid', 'lightgray'),
                    ...shorthands.transition('all', '0s'),
                },
            },

            ':active': {
                color: 'gray',
                backgroundColor: 'white',
                ...shorthands.border('1px', 'solid', 'lightgray'),
                ...shorthands.transition('all', '0s'),
            },
        },
    },
    sendButtonFocused: {
        ...shorthands.border('1px', 'solid', customColors.protivitiOrange),
    },
    paperclip: {
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground1Hover,
        },
    },
    suggestedPrompts: {
        ...SharedStyles.outlineButton,
        ...shorthands.padding('2px', '8px'),
    },
    characterCount: {
        fontFamily: customFonts.Lato,
        fontSize: '12px',
        fontWeight: '700',
    },
    initialAlertContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '-5px', // places it properly in relation to Suggested Prompts button (which needs its padding)
        ...shorthands.padding('0px'),
    },
    initialAlert: {
        color: tokens.colorNeutralForeground1,
        fontFamily: customFonts.Lato,
        fontWeight: tokens.fontWeightRegular,
        minHeight: '20px',
        lineHeight: '1em',
        fontSize: '0.80rem',
        ...Breakpoints.small({ fontSize: '10px' }),
        ...Breakpoints.medium({ fontSize: '.75rem' }),
        boxShadow: 'none',
        alignSelf: 'center',
        ...shorthands.padding('0'),
        ...shorthands.margin('0'),
    },
    toastMessage: {
        fontSize: '18px',
        color: 'black',
        fontFamily: customFonts.TitleFont,
        border: '1px solid black',
        marginTop: '33px',
    },
});

interface ChatInputProps {
    isDraggingOver?: boolean;
    onDragLeave: React.DragEventHandler<HTMLDivElement | HTMLTextAreaElement>;
    onSubmit: (options: GetResponseOptions, signal?: AbortSignal) => Promise<void>;
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    abortController: AbortController;
    setAbortController: React.Dispatch<React.SetStateAction<AbortController>>;
}

const toastAppearance = 3000;

export const ChatInput: React.FC<ChatInputProps> = ({
    isDraggingOver,
    onDragLeave,
    onSubmit,
    setValue,
    abortController,
    setAbortController,
}) => {
    const classes = useClasses();
    const [isFocused, setIsFocused] = React.useState(false);
    const chat = useChat();
    const gptService = useGPT();
    const { userGPTs } = useAppSelector((state: RootState) => state.gpts);
    const [currentGPT, setCurrentGPT] = React.useState<IGPT>();

    const dispatch = useAppDispatch();
    const [inBox, setInBox] = React.useState(false);
    const [valueLocal, setValueLocal] = React.useState('');
    const [documentImporting, setDocumentImporting] = React.useState(false);
    const documentFileRef = useRef<HTMLInputElement | null>(null);
    const { alerts } = useAppSelector((state: RootState) => state.app, shallowEqual);
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations, shallowEqual);
    const { isResponseGenerating, chatInput } = useAppSelector(
        (state: RootState) => ({
            isResponseGenerating: state.currentmessages.generatingMessages[selectedId].isResponseGenerating,
            chatInput: state.currentmessages.generatingMessages[selectedId].chatInput,
        }),
        shallowEqual,
    );
    const [fileCount, setFileCount] = React.useState(0);
    const [allowUpload, setAllowUpload] = React.useState(false);
    const isAPGPT =
        currentGPT?.id === process.env.REACT_APP_AP_POLICYADVISOR_GPT ||
        currentGPT?.id === process.env.REACT_APP_AP_RCMADVISOR_GPT;
    const MAXFILECOUNT = currentGPT?.isDocumentUploadEnabled
        ? isAPGPT
            ? parseInt(process.env.REACT_APP_MAX_FILE_COUNT_UPLOAD_APGPT_THREAD_LEVEL!)
            : parseInt(process.env.REACT_APP_MAX_FILE_COUNT_UPLOAD_THREAD_LEVEL!)
        : parseInt(process.env.REACT_APP_MAX_FILE_COUNT_PER_UPLOAD_PROGPT!);
    const { uploadedDocuments } = conversations[selectedId];
    const isDocumentChat = conversations[selectedId].chatType;
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const positioningRef = React.useRef<PositioningImperativeRef>(null);
    const [isProGPT, setIsProGPT] = React.useState(
        process.env.REACT_APP_DEFAULT_PROGPT === conversations[selectedId].customGPTId ? true : false,
    );
    const _gptService = useGPT();
    const [currentFiles, setCurrentFiles] = React.useState(new Set());

    useEffect(() => {
        setIsProGPT(process.env.REACT_APP_DEFAULT_PROGPT === conversations[selectedId].customGPTId ? true : false);
    }, [conversations, selectedId]);

    // function handles duplicates. Loops through files so that it can add the correct suffix in numerical order
    const handleDuplicate = (file: File, filesArray: File[]) => {
        // getting the different parts of the name
        let splitName = file.name.split('.');
        let baseName = splitName.slice(0, splitName.length - 1).join('.');
        let extension = splitName[splitName.length - 1];

        // find the correct suffix
        let counter = 0;
        let newName: string = file.name;

        while (currentFiles.has(newName) || filesArray.find((i) => i.name === newName)) {
            counter++;
            newName = `${baseName}_${counter}.${extension}`;
        }
        return newName;
    };

    React.useEffect(() => {
        if (uploadedDocuments !== undefined) {
            uploadedDocuments.length >= MAXFILECOUNT ? setAllowUpload(true) : setAllowUpload(false);
        }
    }, [uploadedDocuments]);

    const getResources = () => {
        void chat.getChatMemorySources(selectedId, conversations[selectedId].gptEndpoint).then((sources) => {
            dispatch(
                setDocumentsToConversation({
                    documents: [...sources],
                    chatId: selectedId,
                }),
            );
            setFileCount(sources.length);
            let localFiles = new Set();
            sources?.forEach((x) => {
                localFiles.add(x.name);
            });
            setCurrentFiles(new Set([...localFiles])); // make sure we're keeping the old documents uploaded
        });
    };
    React.useEffect(() => {
        if (uploadedDocuments) {
            setFileCount(uploadedDocuments.length);
            let localFiles = new Set();
            uploadedDocuments?.forEach((x) => {
                localFiles.add(x.name);
            });
            setCurrentFiles(new Set([...localFiles]));
        }
        // We don't want to have chat as one of the dependencies as it will cause infinite loop.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedDocuments, selectedId, documentImporting]);

    React.useEffect(() => {
        fileCount >= MAXFILECOUNT ? setAllowUpload(true) : setAllowUpload(false);
    }, [fileCount]);

    const resizeTextArea = () => {
        if (!textAreaRef.current) {
            return;
        }

        textAreaRef.current.style.height = 'auto'; // will not work without this!
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        if (textAreaRef.current.scrollHeight >= 250) {
            textAreaRef.current.style.backgroundImage = 'none';
        }
    };

    const resizeTextAreaOnBlur = () => {
        if (!textAreaRef.current) {
            return;
        }
        textAreaRef.current.style.height = 'auto';
        textAreaRef.current.style.height = `${textAreaRef.current.style.minHeight}px`;
    };

    React.useEffect(() => {
        resizeTextArea();
    }, [textAreaRef]);

    React.useEffect(() => {
        if (textAreaRef.current) {
            positioningRef.current?.setTarget(textAreaRef.current);
        }
    }, [textAreaRef, positioningRef]);

    useEffect(() => {
        const fetchData = async () => {
            setValue(chatInput);
        };
        fetchData();
    }, [conversations, selectedId, chatInput, setValue]);

    React.useEffect(() => {
        setValueLocal(chatInput);
    }, [conversations, selectedId, chatInput, setValueLocal]);

    useEffect(() => {
        const updatedGPTList = async () => {
            const fetchedGPTs = await gptService.getGPTs();
            dispatch(setUserGPTs({ gpts: fetchedGPTs }));
        };
        updatedGPTList();
    }, [selectedId]);

    const activeGPT = useMemo(() => {
        return (
            userGPTs.find((gpt) => gpt.gptEndpoint === conversations[selectedId]?.gptEndpoint) ??
            userGPTs.find((gpt) => gpt.id === process.env.REACT_APP_DEFAULT_PROGPT)
        );
    }, [userGPTs]);

    useEffect(() => {
        setCurrentGPT(activeGPT);
    }, [selectedId, conversations, userGPTs]);

    // show warning dialog if user tries to refresh before upload requests are sent
    React.useEffect(() => {
        const handleUnload = (event: any) => {
            if (documentImporting) {
                event.preventDefault();
                event.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', handleUnload);
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [documentImporting]);

    const handleImport = (dragAndDropFiles?: FileList) => {
        const files = dragAndDropFiles ?? documentFileRef.current?.files;
        if (files && (files.length > MAXFILECOUNT || files.length + fileCount > MAXFILECOUNT)) {
            toast.error(
                `You can only upload up to ${MAXFILECOUNT} files. Your current file count is: ${fileCount}. Please try uploading with fewer files.`,
                {
                    className: classes.toastMessage,
                },
            );
            if (documentFileRef.current?.value) {
                documentFileRef.current.value = '';
            }
            return;
        }
        if (files && files.length > 0) {
            setDocumentImporting(true);
            // Deep copy the FileList into an array so that the function
            // maintains a list of files to import before the import is complete.
            const filesArray = Array.from(files);

            const maxFileSizeInBytes = currentGPT?.isDocumentUploadEnabled
                ? isAPGPT
                    ? parseInt(process.env.REACT_APP_MAX_FILE_SIZE_APGPT_THREAD_LEVEL!)
                    : parseInt(process.env.REACT_APP_MAX_FILE_SIZE_THREAD_LEVEL!)
                : parseInt(process.env.REACT_APP_MAX_FILE_SIZE_APGPT_THREAD_LEVEL!);

            for (let i = 0; i < filesArray.length; i++) {
                if (currentGPT?.isDocumentUploadEnabled && filesArray[i].size > maxFileSizeInBytes) {
                    toast.error(`File is over ${maxFileSizeInBytes / (1024 * 1024)} MB, please upload a smaller file`, {
                        className: classes.toastMessage,
                    });
                    filesArray.splice(i, 1);
                    i--;
                    continue;
                }
                if (currentFiles.has(filesArray[i].name)) {
                    // this is a duplicate
                    let newName: string = handleDuplicate(filesArray[i], filesArray);
                    filesArray[i] = new File([filesArray[i]], newName, { type: filesArray[i].type }); // create a new file instance (Files are immutable) for the new name
                }
            }
            // go through each file in our array
            filesArray.forEach((file, index) => {
                if (currentFiles.has(file.name)) {
                    // this is a duplicate
                    let newName: string = handleDuplicate(file, filesArray);
                    filesArray[index] = new File([file], newName, { type: file.type }); // create a new file instance (Files are immutable) for the new name
                }
            });
            let remainingFiles = [...filesArray];
            let fileNames = remainingFiles.map((file) => file.name);
            let importingResources = fileNames.map((document, index) => {
                return {
                    id: `in-progress-${index}`,
                    chatId: selectedId,
                    sourceType: 'N/A',
                    name: document,
                    sharedBy: 'N/A',
                    createdOn: 0,
                    tokens: 0,
                    size: 0,
                } as ChatMemorySource;
            });

            uploadedDocuments
                ? dispatch(
                      setDocumentsToConversation({
                          documents: [...importingResources, ...uploadedDocuments],
                          chatId: selectedId,
                      }),
                  )
                : dispatch(setDocumentsToConversation({ documents: [...importingResources], chatId: selectedId }));

            try {
                if (currentGPT?.isDocumentUploadEnabled) {
                    void _gptService.updateThread(filesArray, selectedId).then(() => {
                        getResources();
                        setDocumentImporting(false);
                    });
                } else {
                    for (const file of filesArray) {
                        void chat.importDocument(selectedId, file /*, 'Document'*/).then(() => {
                            remainingFiles = remainingFiles?.filter((fileFromArray) => fileFromArray.name != file.name);
                            if (remainingFiles.length === 0) {
                                getResources();
                                setDocumentImporting(false);
                            }
                        });
                    }
                }
            } catch (error) {
                console.error('Error uploading files:', error);
                setDocumentImporting(false);
                getResources();
            }
        }

        // Reset the file input so that the onChange event will
        // be triggered even if the same file is selected again.
        if (documentFileRef.current?.value) {
            documentFileRef.current.value = '';
        }
    };

    const handleSubmit = (value: string, messageType: ChatMessageType = ChatMessageType.Message) => {
        if (isResponseGenerating) {
            return;
        }

        const regex = new RegExp('[\\p{Cf}\\p{Mn}\\p{Me}\\p{Zl}\\p{Zp}]', 'gu');
        if (regex.test(value)) {
            toast.error('Error: Invalid input. Your input contains invisible characters, please remove and retry.', {
                className: classes.toastMessage,
            });
        } else {
            resizeTextAreaOnBlur();
            if (value.trim() === '') {
                return; // only submit if value is not empty
            }
            setValue('');

            setValueLocal('');
            dispatch(updateChatInput({ chatId: selectedId, text: '' }));
            dispatch(
                updateBotResponseStatus({
                    chatId: selectedId,
                    status: `Dispatching prompt to ${isDocumentChat ? 'DocumentGPT' : currentGPT?.name}`,
                }),
            );

            const controller = new AbortController();
            setAbortController(controller);

            onSubmit(
                {
                    value,
                    messageType,
                    chatId: selectedId,
                    isDocumentChat: isDocumentChat,
                    regenerate: false,
                    gptEndpoint: conversations[selectedId].gptEndpoint,
                    threadRunConfig: conversations[selectedId].threadRunConfig ?? {
                        temperature: 1,
                    },
                },
                controller.signal,
            ).catch((error) => {
                resizeTextAreaOnBlur();
                const message = `Error submitting chat input: ${(error as Error).message}`;
                log(message);
                toast.error(message, {
                    className: classes.toastMessage,
                });
            });
        }
    };

    const handleCancel = () => {
        if (abortController) {
            // you can pass a particular reason, if desired, like this, but you will want to change the else if in the catch statement in useChat to catch it
            abortController.abort('User Cancelled Response');
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLTextAreaElement>) => {
        if (!isDocumentChat) {
            return;
        }
        onDragLeave(e);
        handleImport(e.dataTransfer.files);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        resizeTextArea();
        if (isDraggingOver) {
            return;
        }
        const newInputValue = event.target.value ?? '';
        setValueLocal(newInputValue);
        dispatch(updateChatInput({ chatId: selectedId, text: newInputValue }));
    };
    

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            setValue(valueLocal);
            handleSubmit(valueLocal);
        }
    };

    return (
        <div className={classes.root}>
            {isResponseGenerating && isProGPT && (
                <div className={classes.stopRespondingButtonContainer}>
                    <Button
                        className={classes.stopRespondingButton}
                        icon={<StopFilled />}
                        style={{ transition: 'all 0.3s ease' }}
                        onClick={handleCancel}
                    >
                        Stop Responding
                    </Button>
                </div>
            )}

            <div className={classes.typingIndicator}>
                <ChatStatus />
            </div>
            <Alerts />

            {(isDocumentChat || currentGPT?.isDocumentUploadEnabled) && (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className={classes.functional}>
                        {/* Hidden input for file upload. Only accept .txt and .pdf files for now. */}
                        <input
                            type="file"
                            ref={documentFileRef}
                            style={{ display: 'none' }}
                            accept={
                                currentGPT?.isDocumentUploadEnabled
                                    ? '.csv,.xlsx,.docx,.html,.java,.json,.md,.pdf,.php,.pptx,.py,.rb,.tex,.txt,.css,.jpeg,.jpg,.gif,.png,.tar,.ts,.xml,.zip,.c,.cpp,.cs,.doc,.docx,.html,.java,.json,.md,.pdf,.php,.pptx,.py,.rb,.tex,.txt,.css,.js,.sh,.ts'
                                    : '.txt,.pdf,.md,.jpg,.jpeg,.png,.tif,.tiff,.ppt,.pptx,.doc,.docx'
                            }
                            multiple={true}
                            onChange={() => {
                                handleImport();
                            }}
                        />
                        <Button
                            className={classes.paperclip}
                            disabled={documentImporting || allowUpload}
                            appearance="transparent"
                            icon={<Attach20Filled />}
                            onClick={() => documentFileRef.current?.click()}
                        />
                        {documentImporting && <Spinner size="tiny" />}
                    </div>
                    <div className={classes.spanContainer}>
                        <span className={classes.span}>
                            {currentGPT?.isDocumentUploadEnabled
                                ? isAPGPT
                                    ? `You can upload up to ${parseInt(process.env.REACT_APP_MAX_FILE_COUNT_UPLOAD_APGPT_THREAD_LEVEL!)} documents. The maximum size for each document is ${parseInt(process.env.REACT_APP_MAX_FILE_SIZE_APGPT_THREAD_LEVEL!) / (1024 * 1024)} MB.`
                                    : `You can upload up to ${parseInt(process.env.REACT_APP_MAX_FILE_COUNT_UPLOAD_THREAD_LEVEL!)} documents. The maximum size for each document is ${parseInt(process.env.REACT_APP_MAX_FILE_SIZE_THREAD_LEVEL!) / (1024 * 1024)} MB.`
                                : 'You can upload up to 100 documents. The maximum size for each document is 10 MB. Supported file types are Microsoft Word, PowerPoint, Portable Document Format (PDF), and Text (TXT).'}
                        </span>
                    </div>
                </div>
            )}
            <div
                className={classes.content}
                onMouseEnter={() => {
                    setInBox(true);
                }}
                onMouseLeave={() => {
                    setInBox(false);
                }}
            >
                <Textarea
                    ref={textAreaRef}
                    id="chat-input"
                    textarea={{
                        className:
                            isDraggingOver && isDocumentChat
                                ? mergeClasses(classes.dragAndDrop, classes.textarea)
                                : classes.textarea,
                    }}
                    placeholder={
                        currentGPT?.isDeleted
                            ? `Chat session disabled: ${currentGPT?.name} has been deleted.`
                            : `Message ${isDocumentChat ? 'DocumentGPT' : currentGPT?.name}`
                    }
                    className={classes.input}
                    value={isDraggingOver && isDocumentChat ? 'Drop your files here' : valueLocal}
                    onDrop={handleDrop}
                    onFocus={(event) => {
                        resizeTextArea();
                        setValueLocal(event.target.value);
                        setIsFocused(true);
                    }}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onBlur={() => {
                        dispatch(updateChatInput({ chatId: selectedId, text: valueLocal }));
                        inBox ? '' : resizeTextAreaOnBlur();
                        setIsFocused(false);
                    }}
                    disabled={currentGPT?.isDeleted}
                />
                {!currentGPT?.isDeleted && (
                    <Button
                        aria-label="submit_prompt"
                        appearance="transparent"
                        className={
                            isFocused ? mergeClasses(classes.sendButton, classes.sendButtonFocused) : classes.sendButton
                        }
                        disabled={valueLocal === '' || valueLocal === null || isResponseGenerating}
                        icon={<Arrow />}
                        onClick={() => {
                            setValue(valueLocal);
                            handleSubmit(valueLocal);
                        }}
                    />
                )}
            </div>
            <div className={classes.controls}>
                {!currentGPT?.isDeleted && currentGPT?.promptLibrary && currentGPT.promptLibrary.length > 0 && (
                    <>
                        <Popover positioning={{ positioningRef }}>
                            <PopoverTrigger>
                                <Button className={classes.suggestedPrompts}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <span>Suggested Prompts</span>
                                    </div>
                                </Button>
                            </PopoverTrigger>

                            <PopoverSurface style={{ borderRadius: '10px', width: '60%', height: '60vh' }}>
                                <PopoverContent
                                    setValue={setValueLocal}
                                    isDocumentChat={isDocumentChat}
                                    selectedId={selectedId}
                                />
                            </PopoverSurface>
                        </Popover>
                    </>
                )}
            </div>

            {alerts.length > 0 && alerts[0].message.includes('these requirements') && (
                <div className={classes.initialAlertContainer}>
                    <Alert
                        className={classes.initialAlert}
                        action={{
                            icon: (
                                <Dismiss12Filled
                                    aria-label="dismiss message"
                                    onClick={() => {
                                        dispatch(removeAlert(0));
                                    }}
                                />
                            ),
                        }}
                    >
                        <div>
                            <span>You must follow </span>
                            <span>
                                <a
                                    href="https://roberthalf.sharepoint.com/:b:/r/sites/iShare-GenAI/Shared%20Documents/ProGPT%20Guidelines.pdf?csf=1&web=1&e=svAzdD"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    these requirements
                                </a>
                            </span>
                            <span>
                                {' '}
                                when using ProGPT. You must always review, revise, and verify ProGPT output before you
                                use it in deliverables or thought leadership. Please note data input into ProGPT may be
                                processed in the US.
                            </span>
                        </div>
                    </Alert>
                </div>
            )}
            <ToastContainer autoClose={toastAppearance} />
        </div>
    );
};
